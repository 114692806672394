<template>
  <div class="pa-4" style="max-width: 1000px;min-height:1000px;">
  
    <v-row>
      <v-col cols="12" md="2">
        <v-btn
          v-if="startStep == 2 && showForm != 1"
          class="nocaps mr-4 white--text"
          color="#d84315"
          depressed
          tile
          style="font-size: 18px;"
          @click="resetStep2()"
          >zurück zu Schritt 1</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="9">
        <div v-if="showForm == 1">
<h1>Online-Antrag:  <span class="red--text">{{ tarif.tier_title }}</span></h1>
<v-row class="mt-4">
    <v-col cols="12" md="3">monatlicher Beitrag</v-col>
    <v-col cols="12" md="3"><b>{{ tarif.preis }} €</b></v-col>
</v-row>
<v-row class="mt-4">
    <v-col cols="12" md="3">Versicherungsbeginn</v-col>
    <v-col cols="12" md="3">nächstmöglich</v-col>
</v-row>

<br><br>
<h2>Versicherungsnehmer</h2>
          <v-form ref="antragform" @submit.prevent="submitAntragStep1()">
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  v-model="form.anrede"
                  :items="['Herr', 'Frau']"
                  label="Anrede"
                ></v-select
              ></v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="form.titel" label="Titel"></v-text-field
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[checkTextFeld(form.vorname, 'vorname')]"
                  v-model="form.vorname"
                  label="Ihr Vorname"
                />

                <!-- <div class="pa-2" v-if="errorlist.vorname && startStep == 2">
                  <b class="red--text">Bitte geben Sie Ihren Vornamen an</b>
                </div> -->
                </v-col
              >
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[checkTextFeld(form.nachname, 'nachname')]"
                  v-model="form.nachname"
                  label="Nachname"
                />
                <!-- <div class="pa-2" v-if="errorlist.nachname && startStep == 2">
                  <b class="red--text">Bitte geben Sie Ihren Nachnamen an</b>
                </div> --></v-col
              >
            </v-row>

            <v-row
              ><v-col cols="12" md="9"
                ><v-text-field   :rules="[checkTextFeld(form.ort, 'ort')]" v-model="form.ort" label="Ort"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                :rules="[checkTextFeld(form.plz, 'plz')]"
                  v-model="form.plz"
                  label="Postleitzahl"
                ></v-text-field> </v-col
            ></v-row>

            <v-text-field
            :rules="[checkTextFeld(form.strasse, 'strasse')]"
              v-model="form.strasse"
              label="Straße, Hausnr."
            ></v-text-field>

            <v-text-field
            :rules="[checkTextFeld(form.telefon, 'telefon')]"
              v-model="form.telefon"
              label="Telefon-Nr."
            ></v-text-field>

           

            <v-text-field
            :rules="[checkTextFeld(form.email, 'email')]"
              v-model="form.email"
              label="E-Mail-Adresse"
              type="email"
            ></v-text-field>
            <v-row>
              <v-col cols="12" md="6">
            <v-text-field
            :rules="[checkTextFeld(form.geburtstag, 'geburtstag')]"
              v-model="form.geburtstag"
              label="Geburtsdatum"
              type="date"
            ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
            <v-text-field
              v-model="form.staatsangehoerigkeit"
              label="Staatsangehörigkeit"
            ></v-text-field>
        </v-col></v-row>
            <v-select
              v-model="form.familienstand"
              :items="['ledig', 'verheiratet', 'geschieden', 'verwitwet']"
              label="Familienstand"
            ></v-select>

            <v-btn
              class="nocaps mr-4 white--text"
              color="#d84315"
              depressed
              tile
              style="font-size: 18px;width:100%"
              @click="submitAntragStep1()"
              >weiter zu Schritt 2 von 2</v-btn
            >
          </v-form>
        </div>
        <div v-if="showForm == 2">
          <v-form ref="antragform2" @submit.prevent="submitAntragStep2()">
            <h2>Ihre Bankverbindung - SEPA Lastschriftmandat</h2>
            Von welchem Konto sollen die monatlichen Versicherungsbeiträge
            abgebucht werden?
            <v-text-field
              :rules="[isValidIBANNumber(form.iban, 'iban')]"
              v-model="form.iban"
              label="Iban"
            />

            <div class="pa-2" v-if="errorlist.iban && startStep == 3">
              <b class="red--text">Bitte prüfen Sie Ihre IBAN</b>
            </div>

            <v-text-field
              v-model="form.bank"
              label="Name Ihrer Bank"
            ></v-text-field>

            <v-text-field
              v-model="form.konto_name"
              label="Konto-Inhaber"
            ></v-text-field>

            Ich ermächtige/Wir ermächtigen die {{ tarif.un_name }} zugunsten der
            {{ tarif.un_name }} Zahlungen von meinem/unserem Konto mittels
            Lastschrift einzuziehen. Zugleich weise ich meinen/weisen wir
            unseren Zahlungsdienstleister an, die von
            {{ tarif.un_name }} Versicherungen GbR auf mein/unser Konto
            gezogenen Lastschriften einzulösen. <br /><br />

            <b>Zahlungstermin:</b><br />
            Ich bin/Wir sind damit einverstanden, dass die Abbuchung des
            Beitrages frühestens 5 Tage ab Ausstellungdatum des
            Versicherungsscheines erfolgen wird.
            <br /><br />
            <hr />
            <br /><br />
            <h2>    <span v-if="berechnung.ber_tierart == 1"
                  >Angaben zu Ihrem versicherten Hund</span
                >
                <span v-if="berechnung.ber_tierart == 2"
                  >Angaben zu Ihrer versicherten Katze</span
                ></h2>
            <v-text-field  :rules="[checkTextFeld(form.rasse, 'rasse')]" v-model="form.rasse" label="Rasse"></v-text-field>
            <v-row>
              <v-col cols="12" md="9">
                <span v-if="berechnung.ber_tierart == 1"
                  >Geschlecht Ihres Hundes?</span
                >
                <span v-if="berechnung.ber_tierart == 2"
                  >Geschlecht Ihrer Katze?</span
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.geschlecht">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="weiblich" :value="2" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="männlich" :value="1" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="9">
                <span v-if="berechnung.ber_tierart == 1"></span>
                  Ist Ihr Hund gechipt?</span
                >
                <span v-if="berechnung.ber_tierart == 2">
                  Ist Ihre Katze gechipt</span
                >
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.geschlecht">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="ja" :value="1" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="nein" :value="2" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>
            <br /><br />
            <hr />
            <br /><br />
            <h2>Gesundheitsfragen</h2>
            <v-row>
              <v-col cols="12" md="9"
                >Erhält Ihr Tier derzeit eine tierärztliche Behandlung?</v-col
              >
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.g_frage_1">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Nein" :value="2" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Ja" :value="1" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="9"
                >Wurden Behandlungen oder Operationem tierärztlich empfohlen,
                angeboten oder in Erwägung gezogen?</v-col
              >
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.g_frage_2">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Nein" :value="2" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Ja" :value="1" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="9"
                >Hat     <span v-if="berechnung.ber_tierart == 1"
                  >Ihr Hund</span
                >
                <span v-if="berechnung.ber_tierart == 2"
                  >Ihre Katze</span
                > dauerhafte oder chronischen Erkrankungen.<br />
                (z.B. Epilepsie, Diabetes, Schilddrüsenerkrankungen, Allergien,
                etc.)
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.g_frage_3">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Nein" :value="2" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Ja" :value="1" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="9"
                >Sind angeborene, genetisch bedingten oder erworbenen
                anatomischen Fehlentwicklungen bekannt oder tierärztlich
                festgestellt. <br />(z.B. Dysplasien, Behinderungen,
                Fehlbildungen, etc.)
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.g_frage_4">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Nein" :value="2" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Ja" :value="1" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="9"
                >Ihr Tier hatte in den letzten 6 Monaten vor Antragstellung einen operativen Eingriff?
              </v-col>
              <v-col cols="12" md="3">
                <v-radio-group v-model="form.g_frage_5">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Nein" :value="2" />
                    </v-col>
                    <v-col cols="12" md="6" class="pt-0">
                      <v-radio label="Ja" :value="1" />
                    </v-col>
                  </v-row> </v-radio-group
              ></v-col>
            </v-row>
            <br />
            <v-btn
              class="nocaps mr-4 white--text"
              color="#d84315"
              depressed
              tile
              style="font-size: 18px;width:100%"
              @click="submitAntragStep2()"
              >jetzt Krankenversicherung beantragen (14-Tage
              Rücktrittsrecht)</v-btn
            >
            <div style="border: 2px solid #f2f2f2" class="pa-4 my-4">
              <h2>
                Sie haben ein 14-tägiges Rücktrittsrecht nach Erhalt der Police
              </h2>
              Nach dem Sie die Police erhalten haben, können Sie noch 14 Tage
              vom Vertrag zurücktreten.
              <br />
            </div>
          </v-form>

          <br />
          Die Gesundheitsfragen sind vollständig und wahrheitsgemäß zu
          beantworten, anderenfalls ist die Versicherungsgesellschaft zum
          Rücktritt oder zur Kündigung des Versicherungsvertrages berechtigt und
          sie können ihren Versicherungsschutz verlieren. Bitte nehmen Sie
          ergänzend die Hinweise zur Verletzung der vorvertraglichen
          Anzeigepflicht zur Kenntnis. Bitte beachten Sie hierzu auch die
          Mitteilungen nach §19 Abs. 5 VVG.
          <br />
          Bitte beachten Sie: Für bereits vor Beginn des Versicherungsschutzes
          bekannte bestehende Erkrankungen und Fehlbildungen oder deren klinisch
          relevanter Symptome sowie für bereits
          angeratene/erforderliche/geplante Behandlungen und Operationen besteht
          unabhängig vom Zustandekommen des Vertrages zu keinem Zeitpunkt
          Versicherungsschutz!
        </div>
      </v-col>
    </v-row>
    <div v-if="showForm == 3">
      <v-row class="mt-12">
        <v-col cols="12" md="9"
          ><h2 class="green--text">
            Vielen Dank! Wir bearbeiten Ihren Antrag
          </h2></v-col
        >
      </v-row>
    </div>
  </div>
</template>

<script>
  import baseMixin from "../mixins/baseMixin";

  export default {
    name: "index-got",
    components: {},
    mixins: [baseMixin],
    data: function() {
      return {
        tarif: {},
        berechnung: {},
        startStep: 1,
        showForm: 1,
        errorlist: {
          vorname: false,
          nachname: false,
          iban: false,
        },
        form: {
          ber_id: "",
          un_id: "",
          beitrag: "",
          tarif_id: "",
          tarif_name: "",
          tier_type: "",
          anrede: "",
          titel: "",
          vorname: "",
          nachname: "",
          iban: "",
          bank: "",
          konto_name: "",
          plz: "",
          ort: "",
          strasse: "",
          telefon: "",
          email: "",
          geburtstag: "",
          staatsangehoerigkeit: "deutsch",
          familienstand: "",
          rasse: "",
          geschlecht: 1,
          chip: 1,
          g_frage_1: 2,
          g_frage_2: 2,
          g_frage_3: 2,
          g_frage_4: 2,
          g_frage_5: 2,
        },
      };
    },
    created() {
      this.setTitle();
      this.getTarifBerechnung();
    },

    methods: {
      resetStep2() {
        this.showForm = 1;
      },
      checkStep1Form() {
        let checkPassed = true;

        if (!this.$refs.antragform.validate()) {
          //("dfhfv ");
        } else {
          this.showForm = 2;
          this.updateAbschlussStep(2);
        }
        return checkPassed;
      },
      checkStep2Form() {
        let checkPassed = true;

        if (!this.$refs.antragform2.validate()) {
          //("dfhfv ");
        } else {
          this.showForm = 3;
          this.insertAbschluss();
        }
        return checkPassed;
      },
      insertAbschluss() {
        this.$store.commit("start_loading_full");
        this.$store
          .dispatch("insertAbschluss", this.form)
          .then((resp) => {
            this.$store.commit("stop_loading_full");
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },
      updateAbschlussStep(step) {
        let select = {};
        select.ber_abschluss_step = step
        select.ber_id = this.berechnung.ber_id;
        this.$store
          .dispatch("updateAbschlussStep",select)
          .then((resp) => {
           
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },


      submitAntragStep1() {
        this.startStep = 2;
        this.form.konto_name = this.form.vorname + " " + this.form.nachname;
        if (!this.checkStep1Form()) {
          return false;
        }
      },
      submitAntragStep2() {
        this.startStep = 3;
        if (!this.checkStep2Form()) {
          return false;
        }
      },
      checkTextFeld(text, field) {
        var showError = false;
        if (text == "") {
          showError = true;
          this.errorlist[field] = true;
        } else {
          this.errorlist[field] = false;
        }
        if (showError == true) {
          return false;
        } else {
          return true;
        }
      },
      isValidIBANNumber(input) {
        var CODE_LENGTHS = {
          AD: 24,
          AE: 23,
          AT: 20,
          AZ: 28,
          BA: 20,
          BE: 16,
          BG: 22,
          BH: 22,
          BR: 29,
          CH: 21,
          CR: 21,
          CY: 28,
          CZ: 24,
          DE: 22,
          DK: 18,
          DO: 28,
          EE: 20,
          ES: 24,
          FI: 18,
          FO: 18,
          FR: 27,
          GB: 22,
          GI: 23,
          GL: 18,
          GR: 27,
          GT: 28,
          HR: 21,
          HU: 28,
          IE: 22,
          IL: 23,
          IS: 26,
          IT: 27,
          JO: 30,
          KW: 30,
          KZ: 20,
          LB: 28,
          LI: 21,
          LT: 20,
          LU: 20,
          LV: 21,
          MC: 27,
          MD: 24,
          ME: 22,
          MK: 19,
          MR: 27,
          MT: 31,
          MU: 30,
          NL: 18,
          NO: 15,
          PK: 24,
          PL: 28,
          PS: 29,
          PT: 25,
          QA: 29,
          RO: 24,
          RS: 22,
          SA: 24,
          SE: 24,
          SI: 19,
          SK: 24,
          SM: 27,
          TN: 24,
          TR: 26,
          AL: 28,
          BY: 28,
          CR: 22,
          EG: 29,
          GE: 22,
          IQ: 23,
          LC: 32,
          SC: 31,
          ST: 25,
          SV: 28,
          TL: 23,
          UA: 29,
          VA: 22,
          VG: 24,
          XK: 20,
        };
        var iban = String(input)
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
        // check syntax and length
        if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    
          this.errorlist["iban"] = true;
          //   this.fehleriban.laenge =
          //     "IBAN-Länge stimmt nicht -  richtig: " +
          //     CODE_LENGTHS[code[1]] +
          //     " - aber: " +
          //     iban.length;
          return false;
        }
        // rearrange country code and check digits, and convert chars to ints
        digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function(
          letter
        ) {
          return letter.charCodeAt(0) - 55;
        });
        // final check
        return this.mod97(digits);
      },
      mod97(string) {
        let result = "";
        result = false;
        var checksum = string.slice(0, 2),
          fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
          fragment = String(checksum) + string.substring(offset, offset + 7);
          checksum = parseInt(fragment, 10) % 97;
        }
        if (checksum == 1) {
          result = true;
          this.errorlist['iban'] = false;
        }
        return result;
      },
      checkZahlFeld(frage) {
        var showError = false;
        var inputnumber = parseInt(frage.antrag_input_default);
        var checknumber = parseInt(frage.antrag_input_max);

        if (checknumber > 0 && inputnumber > checknumber) {
          var TextError = "Nur bis maximal " + checknumber + " abschließbar. ";
          showError = true;
        }
        if (showError == true) {
          return TextError;
        } else {
          return true;
        }
      },
      getTarifBerechnung() {
        this.$store.commit("start_loading_full");
        let select = {};
        select.type = "tokentarif";
        select.tarif_id = this.$route.params.tarif_id;
        select.token = this.$route.params.token;
        this.$store.dispatch("getBerechnungErgebnis", select).then((resp) => {
          if (resp.data) {
            this.tarif = resp.data.tarif;
            this.berechnung = resp.data.berechnung;
            this.form.tarif_id = resp.data.tarif.tier_id;
            this.form.tarif_name = resp.data.tarif.tier_title;
            this.form.un_id = resp.data.tarif.un_id;
            this.form.beitrag = resp.data.tarif.preis;
            this.form.ber_id = resp.data.berechnung.ber_id;
            this.form.tier_type = "Hund";
            if (resp.data.berechnung.ber_tierart == 2) {
              this.form.tier_type = "Katze";
            }
            this.updateAbschlussStep(1);
            this.$store.commit("stop_loading_full");
          }
        });
      },
      setTitle() {
        this.$log.debug("title");
        document.title = "EisbaumTabelle Abschluss";
        let metadesc = "";

        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", metadesc);
        document.dispatchEvent(new Event("x-app-rendered"));
      },
    },
  };
</script>
